<template>
  <v-card class="pa-4">
    <v-row no-gutters class="mb-4" align="center" justify-md="space-between">
      <v-col>
        <CardHeader title="Merge errors list" />
      </v-col>

      <v-col
        cols="12"
        md="12"
        lg="4"
        order="2"
        order-lg="1"
        class="mt-2 mt-lg-0"
      >
        <ApAutocomplete
          v-model="searchString"
          placeholder="Search by Primary ID"
          :is-loading="isLoading"
          @input="fetchMergeErrors"
        />
      </v-col>
    </v-row>

    <ApSimpleTable
      :headers="headers"
      :items="mergeErrorsToTheTable"
      :items-per-page="mergeErrorsPerPage"
      :page="page"
      :total-pages="mergeErrorsTotalPages"
      :total-items="mergeErrorsTotalItems"
      @page="onPageChanged"
      @click="openDupeGrid"
    />

    <DupeGridDialog
      v-if="isDupeGridVisible"
      title="Duplicate Set"
      :records="selectedDuplicateSet"
      is-reprocess-enabled
      @close="isDupeGridVisible = false"
    />
  </v-card>
</template>

<script>
import ApSimpleTable from '@/components/common/ApSimpleTable'
import ApAutocomplete from '@/components/common/ApAutocomplete'
import CardHeader from '@/components/shared/CardHeader'
import DupeGridDialog from '@/components/dupegrid/DupeGridDialog'
import { mapState, mapActions } from 'vuex'
import { debounce } from 'lodash-es'

export default {
  components: {
    ApSimpleTable,
    CardHeader,
    ApAutocomplete,
    DupeGridDialog,
  },
  data() {
    return {
      page: 1,
      isLoading: true,
      searchString: '',
      isDupeGridVisible: false,
      selectedDuplicateSet: null,
    }
  },
  computed: {
    ...mapState('dashboard', [
      'mergeErrors',
      'mergeErrorsPerPage',
      'mergeErrorsTotalItems',
      'mergeErrorsTotalPages',
    ]),
    headers() {
      const headerItems = [
        { prop: 'matchConfidenceScore', label: 'MCS' },
        { prop: 'number', label: 'Set ID' },
        { prop: 'mergedAtDateTime', label: 'Merged' },
        { prop: 'mergedBy', label: 'Merged by' },
        { prop: 'primaryId', label: 'Primary ID' },
        { prop: 'errors', label: 'Errors' },
      ]

      return headerItems
    },
    mergeErrorsToTheTable() {
      return this.mergeErrors?.map((job) => {
        return {
          ...job,
          values: this.headers.map((header) => {
            return {
              value: job[header.prop],
            }
          }),
        }
      })
    },
  },
  watch: {
    isDupeGridVisible(val) {
      if (!val) this.fetchMergeErrors()
    },
  },
  created() {
    this.fetchMergeErrors()
  },
  methods: {
    ...mapActions('dashboard', ['getMergeErrors']),
    onPageChanged(page) {
      this.page = page
      this.fetchMergeErrors()
    },
    fetchMergeErrors: debounce(async function () {
      this.isLoading = true
      await this.getMergeErrors({
        page: this.page,
        searchField: 'final_record.Id',
        searchString: this.searchString,
      })
      this.isLoading = false
    }, 200),
    openDupeGrid(duplicateSet) {
      this.selectedDuplicateSet = [duplicateSet]
      this.isDupeGridVisible = true
    },
  },
}
</script>
