<template>
  <v-dialog persistent :value="true" :max-width="dialogWidth">
    <v-card v-if="isLoading" color="primary" dark>
      <v-card-text>
        Please stand by
        <v-progress-linear indeterminate color="white" class="mb-0" />
      </v-card-text>
    </v-card>

    <v-card v-else-if="isError" color="ap-red-10">
      <v-card-title>CleanData Server Message </v-card-title>

      <v-card-text>
        CleanData is hard at work analyzing data to identify and fix data
        quality issues.
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="ap-red" dark @click="$emit('close')"> Close </v-btn>
        <v-btn color="ap-red" dark @click="getDupeSet">Try again</v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-else>
      <DupeGrid
        v-if="response"
        :title="title"
        :type="type"
        :entity-id="records[currentIndex].primaryId"
        :entity-name="records[currentIndex].source"
        :crm-current-url="crmCurrentUrl"
        :user-email-logged-in="userEmailLoggedIn"
        :response="response"
        :has-next="hasNext"
        :has-previous="hasPrevious"
        :is-reprocess-enabled="isReprocessEnabled"
        is-in-dialog
        @next="onNext"
        @previous="onPrevious"
        @close="$emit('close')"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import DupeGrid from './DupeGrid'
import { getDupeSet } from '@/api/dupe-sets/dupe-sets'

export default {
  components: {
    DupeGrid,
  },
  props: {
    title: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: '',
    },
    isMergedDuplicateSets: {
      type: Boolean,
      default: false,
    },
    records: {
      type: Array,
      required: true,
    },
    isReprocessEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      response: null,
      currentIndex: 0,
    }
  },
  computed: {
    ...mapState('auth', ['agentId', 'contextMessage']),
    userEmailLoggedIn() {
      return this.contextMessage.email
    },
    crmCurrentUrl() {
      return this.contextMessage.url
    },
    dialogWidth() {
      if (this.isLoading || this.isError) {
        return 400
      } else {
        return undefined
      }
    },
    hasNext() {
      return this.currentIndex < this.records.length - 1
    },
    hasPrevious() {
      return this.currentIndex > 0
    },
  },
  async created() {
    this.getDupeSet()
  },
  methods: {
    onNext() {
      this.currentIndex = this.currentIndex + 1
      this.getDupeSet()
    },
    onPrevious() {
      this.currentIndex = this.currentIndex - 1
      this.getDupeSet()
    },
    async getDupeSet() {
      this.isLoading = true
      this.isError = false

      try {
        this.response = await getDupeSet(this.agentId, {
          entityId: this.records[this.currentIndex].primaryId,
          entityName: this.records[this.currentIndex].source,
          dupeSetId: this.records[this.currentIndex].id,
        })
        this.response = {
          ...this.response,
          writeCommandsIds: this.records[this.currentIndex].writeCommandsIds,
        }
      } catch {
        this.isError = true
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
